import { Layout as BaseLayout, Drawer, Button } from "antd"
import { FC, Suspense, useEffect, useState } from "react"
import { MenuOutlined } from "@ant-design/icons"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useGeneralContext } from "../../context/GeneralContext"
import { useMeQuery } from "../../graphql"
import { SideMenu } from "./sidebar"
import { useResponsiveContext } from "../../context/ResponsiveContext"
import { Logo } from "../logo"
import './layout.less';
import useLogout from "../../hooks/useLogout"

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
const Layout: FC = () => {
  const { data, loading, refetch, error } = useMeQuery()
  const { shop, setShop, profile, setProfile, isAdmin, setIsAdmin, setIsSalesPerson, setAccount, setShopOptions } = useGeneralContext()
  const [subscription, setSubscription] = useState<string | null | undefined>(undefined)
  const { isMobile } = useResponsiveContext()
  const logout = useLogout();
  const query = useQuery()
  const location = useLocation()
  const navigate = useNavigate()
  const shopQuery = query.get("shop")
  const [drawerVisible, setDrawerVisible] = useState(false)

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    setShopOptions(data?.me?.account?.data?.attributes?.shops?.data?.map(shop => {
      return {
        label: `${shop?.attributes?.name}`,
        value: shop?.id as string,
      }
    }).sort((a, b) => a.label.localeCompare(b.label)) as { label: string; value: string }[])
    setShop(shopQuery ? shopQuery : data?.me?.account?.data?.attributes?.shops?.data?.[0]?.id as string)
    setAccount(data?.me?.account?.data?.id as string)
    setProfile(data?.me as UsersPermissionsUser)
    setIsSalesPerson(data?.me?.role?.data?.attributes?.name === "Sales Staff")
    setIsAdmin(data?.me?.role?.data?.attributes?.name === "SuperAdmin")
    setSubscription(data?.me?.account?.data?.attributes?.subscription?.data?.id)
  }, [location.pathname, data, loading])

  useEffect(() => {
    if (location.pathname === "/" && isAdmin || location.pathname === "/dashboard" && isAdmin) {
      navigate("/owner-dashboard")
    } else if (location.pathname === "/") {
      navigate("/dashboard")
    }
  }, [location.pathname, isAdmin])

  useEffect(() => {
    if (shop && !isAdmin) {
      query.set("shop", `${shop}`)
      navigate(`${location.pathname}?${query.toString()}`, { replace: true })
    }
  }, [loading, isAdmin])

  useEffect(() => {
    const authToken = localStorage.getItem("jwt")

    if (loading) return;

    if (authToken && !data?.me?.account?.data?.attributes?.subscription?.data?.id) {
     navigate("/sign-up/subscription")
    }
    if (!authToken) {
      navigate("/sign-in")
    }

  }, [location.pathname, data, loading])

  useEffect(() => {
    if(!!error && error.graphQLErrors?.[0]?.extensions?.code === "FORBIDDEN") {
     logout()
    }
  }, [error])

  return (
    <BaseLayout style={{ minHeight: "100vh" }} className={`layout ${drawerVisible ? 'without-scroll' : ''}`}>
      <BaseLayout.Sider className={`slider ${drawerVisible ? 'open' : ''}`}>
        {profile && <SideMenu onCloseMenu={() => { setDrawerVisible(false) }}/>}
      </BaseLayout.Sider>
      <BaseLayout style={{ backgroundColor: "#F6F8F9" }}>
        <Suspense>
          <BaseLayout.Header className={'header'}>
            <div style={{
              display: "flex",
              position: "relative",
              right: "16px",
            }}>
              <Logo />
            </div>
            <Button type="text" icon={<MenuOutlined />}
                    className={'menu'}
                    onClick={() => {
              setDrawerVisible(prev => !prev)
            }} />
          </BaseLayout.Header>
          {
            data?.me && <Outlet />
          }
        </Suspense>
      </BaseLayout>
    </BaseLayout>
  )
}

export { Layout }
